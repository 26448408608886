.App {
  text-align: center;
  background-color: #F2C94C;
}

.Loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  position: fixed;
  transform: translate(-50%,-50%);
  top: 45%;
  left: 49%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  pointer-events: none;
}

.App-header {
  background-color: #F2C94C;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


html { scroll-behavior: smooth; } 

.Img {
  display: block;
  margin: auto;
}


h4 {
  text-align: center;
  font-size: 1.5rem !important;
}

h2 {
  margin-bottom: 20px !important;
  font-size: 1.3rem !important;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}


p {
  margin-bottom: 0.7rem;
}

span, p, div, a, button, input {
  font-size: 0.71rem;

}




.btn-box {
  display:inline-block;
  margin: auto;
}

.btn-1{
  margin: 0 1em 0 1em;
}

.smiley-box{
  text-align: center;
}


.sub-class {
  text-align: center;
  animation: forwards backwards 1.2; 
  vertical-align: sub;
  content: 'ping www.google.com'

}


.active-list{
  margin-top: 2em
}

.active-input{
  width: 600px;
}



.error-msg{
  color: red;
}


.button{
 background-color: blue !important;
 padding: 0.5em 1em !important;
 color: white !important;
 text-transform: initial !important;
 font-size: 16px !important;
 cursor: pointer;
}

.small-btn {
  padding: 0.2em 0.2em !important;
  font-size: 10px !important;
}

.space-right {
  margin-right: 7px !important;
}

.glow {
  background-color: rgb(227, 225, 65);
  text-align: center;
  -webkit-animation: glow 0.75s ease-in-out infinite alternate;
  -moz-animation: glow 0.75s ease-in-out infinite alternate;
  animation: glow 0.75s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    opacity: 0.1;
  }
  
  to {
    opacity: 0.7;
  }
}

.chat-highlight {
  -webkit-animation: chat-highlight 3s cubic-bezier(0.22, 0.61, 0.36, 1); 
  -moz-animation: chat-highlight 3s cubic-bezier(0.22, 0.61, 0.36, 1);
  animation: chat-highlight 3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

@keyframes chat-highlight{
  from{
    background-color: yellow;
    border-color: yellow;
  }

  to{
    background-color: white;
    border-color: white;
  }
}



.square {
  width: 100px;
  height: 100px;
  background-color: #3498db;
  position: absolute;
}


.pos1{

  top: 10px;
  left: 80px;
}

.pos2{
  top: 800px;
  left: 800px;
}


.user-chat {
  margin-right: 3em;
  margin-left: auto;
}


@-webkit-keyframes slide {
  100% { top: 10px }
}

@keyframes slide {
  100% { top: 10px }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}



/* Basic overlay styling */
.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.highlight {
  position: absolute;
  border: 2px solid #6366F1; /* Purple border to highlight element */
  border-radius: 8px;
  pointer-events: none;
}

/* Popover styling OVERLAY */ 
.tutorial-box {
  background-color: white;
  border: 2px solid #6366F1;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  z-index: 1001;
}

.tutorial-box .left {
  margin-right: 10px;
}

.tutorial-box .left img {
  width: 200px;
  height: 200px;
}

.tutorial-box .right h4 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.tutorial-box .right p {
  margin: 5px 0;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
}

.tutorial-box .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  left: 360px;
  position: relative;
  width: 120px;

}

.tutorial-box .actions button {
  background-color: #6366F1;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.tutorial-box .actions button:hover {
  background-color: #4f46e5;
}


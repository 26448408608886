.Toastify__toast-body {
    font-size:16px;
    white-space: pre
}

.nodeErrorHighlight{
    animation: blinkError 1s infinite;
}

.nodeWarningHighlight{
    animation: blinkWarning 1s infinite;
}

@keyframes blinkError { 50% { border: 2px solid red;}  }

@keyframes blinkWarning { 50% { border: 2px solid yellow;}  }


.container {
    position: relative;
    transform: translate(47%, 37%);
}
.container .box {
  width: 60px;
  height: 100px;
  background: red;
  border-radius: 4px;
  position: absolute;
  transform: origin center;
  @for $i from 1 through 6 {
    &:nth-child(#{$i}) {
      animation-delay: 0.1s * ($i);
    }
  }
}
.container .box:nth-child(1) {
  animation: move1 1.4s ease-out infinite;
  background: #4173D6;
  z-index: 6;
}
.container .box:nth-child(2) {
  animation: move2 1.4s ease-out infinite;
  background: #F2C94C;
  z-index: 5;
}
.container .box:nth-child(3) {
  animation: move3 1.4s ease-out infinite;
  background: #FFAA0D;
  z-index: 4;
}
.container .box:nth-child(4) {
  animation: move4 1.4s ease-out infinite;
  background: #FFF;
  z-index: 3;
}
.container .box:nth-child(5) {
  background: #c21c44;
  z-index: 2;
}
.container .box:nth-child(6) {
  background: #882769;
  z-index: 1;
}

@keyframes move1{
  0%{}
  15%{
    transform: translate(-80px);
  }
  40%{
    transform: translate(-80px, -50px);
  }
  60%{
    transform: translate(-80px, 0px);
  }
  80%{
    transform: translate(-80px, 0px);
  }
  90%{
    transform: translate(0px);
  }
  100%{
    transform: translate(0px);
  }
}
@keyframes move2{
  0%{}
  20%{
    transform: translate(-60px);
  }
  50%{
    transform: translate(-60px, -50px);
  }
  60%{
    transform: translate(-60px, 0px);
  }
  80%{
    transform: translate(-60px, 0px);
  }
  90%{
    transform: translate(0px);
  }
  100%{
    transform: translate(0px);
  }
}

@keyframes move3{
  0%{}
  25%{
    transform: translate(-40px);
  }
  55%{
    transform: translate(-40px, -50px);
  }
  65%{
    transform: translate(-40px, 0px);
  }
  80%{
    transform: translate(-40px, 0px);
  }
  90%{
    transform: translate(0px);
  }
  100%{
    transform: translate(0px);
  }
}

@keyframes move4{
  0%{}
  30%{
    transform: translate(-20px);
  }
  60%{
    transform: translate(-20px, -50px);
  }
  70%{
    transform: translate(-20px, 0px);
  }
  80%{
    transform: translate(-20px, 0px);
  }
  90%{
    transform: translate(0px);
  }
  100%{
    transform: translate(0px);
  }
}



// @keyframes move1 {
//   0% {
//   }
//   15% {
//     transform:
//       translate(-80px);
//       // rotate(90deg);
//   }
//   35% {
//     transform: translate(-80px);
//     // transform: translate(0px);
//     // rotate(270deg);
//   }
//   60% {
//     transform: translate(-80px, -50px);
//     // transform: rotate(360deg);
//   }
//   85% {
//     transform: translate(-80px, 0px);
//   }
//   100% {
//     transform: translate(0px);
//     // transform: rotate(360deg);
//   }
// }

// @keyframes move2 {
//   0% {
//   }
//   15% {
//     transform:
//       translate(-60px);
//   }
//   30% {
//     transform: translate(-60px);
//   }
//   60% {
//     transform: translate(-60px, -50px);
//   }
//   80% {
//     transform: translate(-60px, 0px);
//   }
//   100% {
//     transform: translate(0px);
//   }
// }

// @keyframes move3 {
//   0% {
//   }
//   15% {
//     transform:
//       translate(-40px);
//   }
//   35% {
//     transform: translate(-40px);
//   }
//   45%  {
//     transform: translate(-40px);
//   }
//   75% {
//     transform: translate(-40px, -50px);
//   }
//   85% {
//     transform: translate(-40px, 0px);
//   }
//   100% {
//     transform: translate(0px, 0px);
//   }
// }

// @keyframes move4 {
//   0% {
//   }
//   15% {
//     transform:
//       translate(-20px);
//   }
//   35% {
//     transform: translate(-20px);
//   }
//   50%  {
//     transform: translate(-20px);
//   }
//   75% {
//     transform: translate(-20px, -50px);
//   }
//   95% {
//     transform: translate(-20px, 0px);
//   }
//   100% {
//     transform: translate(0px, 0px);
//   }
// }


.helpFab {
    background-color: #c4c4c4 !important;
    bottom: 1rem;
    box-shadow: none !important;
    outline: none !important;
    position: fixed !important;
    right: 1rem;
    z-index: 10;
}

.helpFabIcon {
    color: #fff;
    font-size: 25px;
}

.glow {
    font-family:arial;
    margin-bottom:100px;
    background:#4173D6;
    padding:0.2rem 3.3rem 0.2rem 3.3rem;
    color:white;
    pointer-events: auto;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

.glow:focus{
    outline:"none"
}

.glow:disabled{
    background:#c4c4c4;
    color:#fff;
}

@keyframes glow {
    0% {
      background-color: #4173D6;
      box-shadow: 0 0 5px #4173D6;
    }
    50% {
      background-color: #3762b8;
      box-shadow: 0 0 20px #3762b8;
    }
    100% {
      background-color: #2e539e;
      box-shadow: 0 0 5px #2e539e;
    }
  }
.card {
  background: transparent;
  box-shadow: none;
  font-family: "Montserrat", sans-serif;
  height: 350px;
  position: absolute;
  overflow: hidden;
  width: 230px;

  border: none;
  border-color: transparent;
  border-style: solid !important;
  border-width: thick !important;
  border-radius: var(--card-border-radius) !important;

  &[data-card-selected="true"] {
    border-color: var(--card-border-colour) !important;
  }
}

.n_card {
  background: transparent;
  font-family: "Montserrat", sans-serif;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  margin-bottom: -280px;
  width: 230px;
  height: 350px;
  border-radius: 10px;

  * {
    box-sizing: border-box;
  }
}

.deck_card {
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  margin-bottom: -355px;
  width: 230px;
  height: 350px;
  border-radius: 10px;
}

/* Front Card Cover */
.cardContent {
  position: absolute;
  height: calc(100%);
  padding: 20px;
  top: 0;
  left: 0;
  background: #fff;
  transition: 0.5s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  border: 0.5px solid #c4c4c4;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
}

.cardContentFlip {
  position: absolute;
  height: calc(100%);
  padding: 20px;
  background: #fff;
  top: 0;
  left: 0;
  transition: 0.5s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  border: 0.5px solid #c4c4c4;
  border-radius: 10px;
  opacity: 0;
  z-index: 0;
}

.iconRight {
  float: right;
  &:focus {
    outline: none;
  }
}

.sendRight {
  float: right;
  color: #4173d6;
  opacity: 1;
  &:focus {
    outline: none;
  }
}

.sendRightHide {
  float: right;
  color: #4173D6;
  opacity: 0;
}

.contentWrapper {
  position: relative;
  margin: 10px 0 10px 0;
  min-width: 188px;
  height: calc(100% - 140px);
}

.content {
  font-family: Inter;
  font-weight: 500;

  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;

  &:focus {
    outline: 0px solid transparent;
  }
}

.counterHide {
  opacity: 0;
}

.username {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.pin {
  font-size: 12px;
  margin-left: 2px;
  margin-top: 2px;
}

/* Back Card Cover */
.cardCover {
  position: absolute;
  height: calc(100%);
  width: 100%;
  padding: 20px;
  transition: 0.5s;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 1;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  border: 0.5px solid #c4c4c4;
  border-radius: 10px;
  z-index: 0;
}

.cardCoverFlip {
  position: absolute;
  height: calc(100%);
  width: 100%;
  padding: 20px;
  top: 0;
  left: 0;
  transition: 0.5s;
  background: #fff;
  opacity: 1;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  border: 0.5px solid #c4c4c4;
  border-radius: 10px;
  z-index: 1;
}

.cardCoverIconWrapper {
  margin: 40px 0;
}

.cardCoverCategory {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.cardCoverInfo {
  font-size: 14px;
  text-align: center;
}

.menuIcon {
  margin-right: 5px;
}

.imgHolder {
  position: relative;
  width: 100%;
  height: 170px;
  overflow: hidden;

  &:hover {
    .imgDelete {
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
    }
  }
}

.imgDelete {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    outline: none;
    height: 20px;
    background: transparent;
    color: transparent;
    z-index: 2;
    transition: 0.5s;
}

.dropZone {
  height: 170px;
  border: 1px dashed #c4c4c4;
  background: #f2f2f2;
  color: #c4c4c4;
  line-height: 160px;
  font-size: 12px ;
  font-family: 'Montserrat';
  text-align: center;
  width: 100%;
}

.draggableStackContainer {
  border: none;
  border-color: transparent;
  border-style: solid !important;
  border-width: thick !important;
  border-radius: var(--card-border-radius) !important;
  overflow: hidden;
}

.draggableStackContainerSelected {
  border-color: var(--card-border-colour) !important;
}

#contentEditable, #contentEditable a, #contentEditable div{
    font-size:20px;
    text-overflow: ellipsis;
}

#contentEditableHand, #contentEditableHand a, #contentEditableHand div{
    font-size:16px;
    text-overflow: ellipsis;
}

#contentEditable a, #contentEditableHand a{
    cursor:pointer
}
.container {
  background: #d1d1d1;
  border-radius: 10px;
  color: #4f4f4f;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  outline: none;
  margin: 0;
  margin-top: 10px;
  margin-right: 10px;
  width: fit-content;
}

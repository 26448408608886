
.loader {
    width: 130px;
    height: 170px;
    position: absolute;
    z-index: 2;
    top: 40%;
    left: 55%;
    transform: translate(-50%,-50%);
  }
  .loader::before,
  .loader::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 30px;
    left: 15px;
    z-index: 1;
    transform: scale(0);
    transition: all 0.2s ease;
  }
  .loader .txt {
    width: 120%;
    text-align: center;
    position: absolute;
    bottom: -30px;
    left: -7%;
    font-family: "Abel", sans-serif;
    font-size: 12px;
    letter-spacing: 2px;
    color: white;
  }
  .loader .code {
    position: absolute;
    z-index: 99;
    bottom: -3px;
    animation-name: spaceboots;
    animation-duration: 0.8s;
    transform-origin: 50% 50%;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: spaceboots;
    -webkit-animation-duration: 0.8s;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  }
  .loader .box {
    width: 100%;
    height: 140px;
    display: block;
    color: white;
    position: absolute;
    top: -63px;
    left: -18px;
    z-index: 2;
    overflow: hidden;
  }
  .loader .box::before,
  .loader .box::after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .loader .box:nth-child(1)::before {
    font-size: 20px;
    content: url("images/outline_account_tree_black_18dp.png");
    animation: a 1.1s linear infinite;
  }
  .loader .box:nth-child(1)::after {
    content: url('images/outline_flash_on_black_18dp.png');
    animation: b 1.3s linear infinite;
  }
  .loader .box:nth-child(2)::before {
    content: url('images/outline_timeline_black_18dp.png');
    font-size: 25px;
    animation: c 0.9s linear infinite;
  }
  .loader .box:nth-child(2)::after {
    content: url('images/outline_person_outline_black_18dp.png');
    animation: d 0.7s linear infinite;
  }
  .loader.amit::before,
  .loader.amit::after {
    transform: scale(1);
  }
  
  @keyframes a {
    0% {
      transform: translate(30px, 0) rotate(30deg);
      opacity: 0;
    }
    100% {
      transform: translate(30px, 150px) rotate(-50deg);
      opacity: 1;
    }
  }
  @keyframes b {
    0% {
      transform: translate(50px, 0) rotate(-40deg);
      opacity: 0;
    }
    100% {
      transform: translate(40px, 150px) rotate(80deg);
      opacity: 1;
    }
  }
  @keyframes c {
    0% {
      transform: translate(70px, 0) rotate(10deg);
      opacity: 0;
    }
    100% {
      transform: translate(60px, 150px) rotate(70deg);
      opacity: 1;
    }
  }
  @keyframes d {
    0% {
      transform: translate(30px, 0) rotate(-50deg);
      opacity: 0;
    }
    100% {
      transform: translate(45px, 150px) rotate(30deg);
      opacity: 1;
    }
  }
  @keyframes spaceboots {
    0% {
      transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
  
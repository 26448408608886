.helpPopoverList {
    background: white;
    border: 1.8px solid #D1D1D1;
    border-radius: 10px;
}

.helpPopoverListItemButton {
    padding-right: 3rem;

    &:hover {
        color: initial;
    }
}
